import React from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import { all } from "q"




export default ({ data }) => {


  const allTitles = data.allWordpressWpTitles.edges



  //console.log(data)
  return (
    <div>

      <Helmet>
        <meta charSet="utf-8" />
        <title>People</title>
        <link rel="canonical" href="https://grmandco.com/artists" />
      </Helmet>

      <h1 className="page_title">Artists</h1>

      <div className="main_content artists">

        {data.allWordpressWpPeople.edges.map(edge => {
          const wpid = edge.node.wordpress_id;
          const matchingTitles = allTitles.filter(title => {
            return title.node.people.indexOf(wpid) > -1
          })


          if (matchingTitles.length > 1) {
            return (<p><Link to={"/people/" + edge.node.slug}>{edge.node.name}</Link></p>)
          } else if (matchingTitles.length === 1) {
            const firstTitleSlug = matchingTitles[0].node.slug;

            return (<p><Link to={"/titles/" + firstTitleSlug}>{edge.node.name}</Link></p>)
          } else {

            return (<p className="noone">{edge.node.name}</p>)
          }
        })}
      </div>
    </div>
  )
}

export const query = graphql`
  query {


    allWordpressWpPeople(sort:{fields:acf___last_name, order: ASC}) {
      edges {
        node {
          wordpress_id
          name
          description
          slug
        }
      }
    }

    allWordpressWpTitles {
      edges {
        node {
          id
          people
          slug
        }
      }
    }
  }
`
